import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PostServiceFeedbackComponent } from './post-service-feedback/post-service-feedback.component';
import { GenericFeedbackComponent } from './generic-feedback/generic-feedback.component';
import { NotificationComponent } from './notification/notification.component';
import { MatFormFieldModule, MatChipsModule, MatCardModule, MatOptionModule, MatSelectModule, MatInputModule,MatTableModule,MatDatepickerModule,MatNativeDateModule } from '@angular/material';
import { MatPaginatorModule, MatProgressSpinnerModule, 
  MatSortModule, MatSort,MatMenuModule,MatIconModule,
  MatToolbarModule,
  MatButtonModule,MatDialogModule,MatSnackBarModule } from '@angular/material';
  import { MyDatePickerModule } from 'mydatepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { AddNotificationChassisComponent } from './add-notification-chassis/add-notification-chassis.component';  

import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { AppRoutes } from "./app-routing.module";
import { DialogComponent } from './dialog/dialog.component';
import { CommondialogComponent } from './commondialog/commondialog.component';
// import { FileSelectDirective,FileUploader } from 'ng2-file-upload';
@NgModule({
  declarations: [
    AppComponent,
    SimpleDialogComponent,
    DashboardComponent,
  	LoginComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PostServiceFeedbackComponent,
    GenericFeedbackComponent,
    NotificationComponent,
    AddNotificationComponent,
    AddNotificationChassisComponent,
    DialogComponent,
    CommondialogComponent,
    // FileSelectDirective,
    // FileUploader
  ],
  imports: [
  BrowserModule,
	MatPaginatorModule,
	MatSortModule,
  MatTableModule,
	BrowserAnimationsModule,
	MyDatePickerModule,
  Ng4LoadingSpinnerModule.forRoot(),
  //RouterModule.forRoot(AppRoutes),
   // AppRoutingModule,
      RouterModule.forRoot(AppRoutes, { useHash: true }),
	FormsModule,
	ReactiveFormsModule,
  HttpClientModule,
  MatCardModule,
 MatDialogModule,
 MatSnackBarModule
//  SimpleDialogComponent
  ],
  entryComponents: [
    SimpleDialogComponent,
    CommondialogComponent
    // FileSelectDirective,
    // FileUploader
    ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
