import { Component, OnInit,ViewChild,ChangeDetectorRef } from '@angular/core';
import { NgModule } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatPaginator, MatTableDataSource, MatInput } from '@angular/material';
import {
  MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
  MatSortModule, MatTableModule, MatSort,MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PageEvent } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
 import {FormsModule,} from '@angular/forms';
import { FormBuilder , FormGroup, Validators, ValidationErrors } from '@angular/forms';
import * as $ from "jquery";
import bootstrap from 'node_modules/bootstrap'
// import { SimpleDialogComponent } from 'src/simple-dialog/simple-dialog.component';
import { SimpleDialogComponent } from 'src/app/simple-dialog/simple-dialog.component'
// import 'bootstrap';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

export class NotificationComponent implements OnInit {
	simpleDialog: MatDialogRef<SimpleDialogComponent>;
	length = 0;
	pageSize = 5;
	// pageSizeOptions: number[] = [5, 10, 25, 100];
	pageSizeOptions: number[] = [5];

	// MatPaginator Output
	pageEvent: PageEvent;
	dataSource;
	displayedColumns = [];
	psfdata:any='';

	@ViewChild(MatSort) sort: MatSort;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	
	 columnNames = [
		{
			id:"notification_id",
			value: "Notification Id"
		},
		{
			id:"created_by",
			value: "Created By"
		},
		{
			id:"created_dt",
			value: "Created At"
		},
		{
			id:"last_updated_by",
			value: "Last Updated By"
		},
		{
			id:"last_updated_at",
			value: "Last Updated At"
		},
		{
			id:"notification_type",
			value: "Notification Type"
		},
		{
			id:"notification_title",
			value: "Title"
		},
		{
			id:"notification_desc",
			value: "Description"
		},
		{
			id:"notification_category",
			value:"Category"
		},
		{
			id:"notification_base_category",
			value:"Base Category"
		},
		{
			id:"notification_startDate",
			value: "Start Date"
		},
				{
			id:"notification_endDate",
			value: "End Date"
		},
				{
			id:"associated_states",
			value: "State"
		},
				{
			id:"associated_cities",
			value: "City"
		},
				{
			id:"associated_ppl",
			value: "PPL"
		},
		{
			id:"associated_pl",
			value: "PL"
		},
		{
			id:"kms_run",
			value: "Kms Run"
		},
		{
			id:"is_chassis_flag",
			value: "Is Chassis"
		},
		{
			id:"associated_chassis",
			value:"Associated Chassis"
		},
		{
			id:"is_active",
			value: "Published(Yes/No)"
		},
		{
			id:"action",
			value: "Action"
		}
		
		];
		
	
	public myDate: IMyDate = {

		year: this.getCurrentDay('Y'),
		month: this.getCurrentDay('M'),
		day: this.getCurrentDay('D'),
	}
  
  
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    
    
    var day = today.getDate();
    if (day != 1) {
      day = day - 1;
    }
    
    var month = today.getMonth() + 1; //January is 0!
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    markCurrentDay: true,
  }; 
   jc_to_date:any='';
  feedback_from_date:any='yyyy-mm-dd';
  feedback_to_date:any='yyyy-mm-dd';
  jc_from_date:any='';
	offset: number = 0;

	message: string = 'Snack Bar opened.';
  //actionButtonLabel: string = 'Retry';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  addExtraClass: boolean = false;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private _ser:BaseapiService,private _r:Router,private _fb:FormBuilder, private _ar: ActivatedRoute,private changeDetectorRefs: ChangeDetectorRef,private dialogModel: MatDialog,public snackBar: MatSnackBar) {
		
	}
	onJcFromDateChanged(event: IMyDateModel) {
		this.jc_from_date = event.formatted;
		console.log(this.jc_from_date);
	  }
	  onJcToDateChanged(event: IMyDateModel) {
		this.jc_to_date = event.formatted;
		console.log(this.jc_to_date);
	  }
	  res;
	  notificationData:any={};
	  globalData:any=[];
	  globalDataTable:any=[];
	ngOnInit() {
		this.offset = 0;
		var tabname="notification"
		this._ar.queryParams.subscribe(params => {
		//	let date = params['startdate'];
			console.log(JSON.stringify(params)); // Print the parameter to the console. 
	});
	$('.addnotification').click(function(){
		$('ul.nav li a').removeClass("active1")
		
		// $(this).css("background-color: #213a89")
	 $('.addnotification').addClass("active1")
		$('.viewnotification').addClass("active");
	});
		// alert(this._ar.snapshot.params)
		console.log(JSON.stringify(this._ar.snapshot.params))
	//	this.dialog()
		this._ser.changeUrl('true');
		this.displayedColumns = this.columnNames.map(x => x.id);
		// console.log(this.displayedColumns);
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		this.generateTable(0,5,fromDate,toDate);
	}
	dialog() {
    // alert()
    this.simpleDialog = this.dialogModel.open(SimpleDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });
    }
	pageEventHandle(event) {
    this.changeDetectorRefs.detectChanges();
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		
		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = fromDate;
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			  var jcToDate = toDate;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
		
		this.generateTable(event.pageIndex * 5, event.pageSize,jcFromDate,jcToDate);
	}
    generateTable(offset,size,fromDate,toDate){
		this.length =  10 //data['total_results'];
		
		this.spinnerService.show();
		var request = {
			"app_name": localStorage.getItem('app_name'),
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": localStorage.getItem('device_id'),
			"from_date":fromDate, 
			"to_date":toDate, 
			"offset":offset,
			"size":size
		};
			
		this._ser.getNotification(request).subscribe(
			res => {
				this.spinnerService.hide()
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['notification_list']);
				
				for(var i:any=0;i<res['notification_list'].length;i++){
					let jsonObj2 = {};
					//console.log(this.notificationData.notification_list[i]);
					res['notification_list'][i].notification_id=res['notification_list'][i].notification_id;
					res['notification_list'][i].created_by=res['notification_list'][i].created_by;
					res['notification_list'][i].created_dt=res['notification_list'][i].created_dt;
					res['notification_list'][i].last_updated_by=res['notification_list'][i].updated_by;
					res['notification_list'][i].last_updated_at= res['notification_list'][i].updated_dt;
					res['notification_list'][i].notification_type=res['notification_list'][i].notification_type;
					res['notification_list'][i].notification_title=res['notification_list'][i].notification_title;
					res['notification_list'][i].notification_desc=res['notification_list'][i].notification_desc;
					res['notification_list'][i].notification_category=res['notification_list'][i].notification_category;
					res['notification_list'][i].notification_base_category=res['notification_list'][i].notification_base_category;
					res['notification_list'][i].notification_startDate=res['notification_list'][i].notification_startDate;
					res['notification_list'][i].notification_endDate=res['notification_list'][i].notification_endDate;
					res['notification_list'][i].associated_states=res['notification_list'][i].associated_states.toString();
					
					if(res['notification_list'][i].associated_cities!=''){
						res['notification_list'][i].associated_cities=res['notification_list'][i].associated_cities.toString();
						}else{
						res['notification_list'][i].associated_cities='All';
						}
					//res['notification_list'][i].associated_cities=res['notification_list'][i].associated_cities.toString();
					
					res['notification_list'][i].associated_ppl=res['notification_list'][i].associated_ppl.toString();
					res['notification_list'][i].associated_pl=res['notification_list'][i].associated_pl.toString();
					res['notification_list'][i].kms_run=res['notification_list'][i].kms_run;
					//jsonObj2['Kms Run Operator']=res['notification_list'][i].kms_run_operator;
					if(res['notification_list'][i].is_chassis_flag){
					res['notification_list'][i].is_chassis_flag='Yes';
					}else{
					res['notification_list'][i].is_chassis_flag='No';
					}
					res['notification_list'][i].associated_chassis =res['notification_list'][i].associated_chassis.toString();;
					res['notification_list'][i].is_active=res['notification_list'][i].is_active;
					if(res['notification_list'][i].is_active){
					res['notification_list'][i].is_active='Yes';
					}else{
					res['notification_list'][i].is_active='No';
					}
				  // this.globalDataTable.push(jsonObj2);
					
				}
				console.log(res['notification_list']);
				this.length = res['total_count'];
					this.dataSource = new MatTableDataSource(res['notification_list']);
					//console.log(' hii '+this.dataSource);
					this.dataSource.sort = this.sort;
			//this.globalDataTable=res['notification_list'];
			},
			error => {
				let config = new MatSnackBarConfig();
				config.verticalPosition = this.verticalPosition;
				config.horizontalPosition = this.horizontalPosition;
				config.duration = this.setAutoHide ? this.autoHide : 0;
				if (error.status == 401) {
					this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
					
					//alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

					//alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
				//alert(error.error.detail)
				//this._r.navigate(['/login']);
				//this.setResult(error);
			}
		);
		
		
		
		//this.psfdata=JSON.parse(this.globalData);
		//console.log(this.globalDataTable);
	
	}
	downloadAsExcelFile()
	{
		this.offset=0;
		this.globalData=[]
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		
		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = fromDate;
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			  var jcToDate = toDate;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
		var request = {
			"app_name":localStorage.getItem('app_name'),
			// "app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"offset": 0,
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"size":5
		};
			
		this._ser.getNotification(request).subscribe(
			res => {
				this.spinnerService.show();
				// this.globalData=[];
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(jsonObj);
				var num_list_cnt: number = this.notificationData.total_count;
			
				// if(num_list_cnt!= 0){
				for(var i:any=0;i<this.notificationData.notification_list.length;i++){
					let jsonObj2 = {};
					jsonObj2['Notification Id']=this.notificationData.notification_list[i].notification_id;
					jsonObj2['Created By']=this.notificationData.notification_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.notification_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.notification_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.notification_list[i].updated_dt;
					jsonObj2['Type']=this.notificationData.notification_list[i].notification_type;
					jsonObj2['Title']=this.notificationData.notification_list[i].notification_title;
					jsonObj2['Description']=this.notificationData.notification_list[i].notification_desc;
					jsonObj2['Notification Category']=this.notificationData.notification_list[i].notification_category;
					jsonObj2['Notification Base Category']=this.notificationData.notification_list[i].notification_base_category;
					jsonObj2['Start Date']=this.notificationData.notification_list[i].notification_startDate;
					jsonObj2['End Date']=this.notificationData.notification_list[i].notification_endDate;
					jsonObj2['State']=this.notificationData.notification_list[i].associated_states.toString();
					jsonObj2['City']=this.notificationData.notification_list[i].associated_cities.toString();
					jsonObj2['PPL']=this.notificationData.notification_list[i].associated_ppl.toString();
					jsonObj2['PL']=this.notificationData.notification_list[i].associated_pl.toString();
					jsonObj2['Kms Run']=this.notificationData.notification_list[i].kms_run;
					//jsonObj2['Kms Run Operator']=this.notificationData.notification_list[i].kms_run_operator;
					jsonObj2['IsChassis']=this.notificationData.notification_list[i].is_chassis_flag;
					if(this.notificationData.notification_list[i].is_chassis_flag){
						jsonObj2['IsChassis'] = 'Yes';
					}else{
						jsonObj2['IsChassis'] = 'No';
					}
					jsonObj2['Chassis'] =this.notificationData.notification_list[i].associated_chassis;
					jsonObj2['Is Published']=this.notificationData.notification_list[i].is_active;
					if(this.notificationData.notification_list[i].is_active){
						jsonObj2['Is Published'] = 'Yes';
					}else{
						jsonObj2['Is Published'] = 'No';
					}
				   this.globalData.push(jsonObj2);
					
				}
				var num1: number = this.notificationData.total_count;
				  var num2: number = this.notificationData.notification_list.length;
				  var res1: number = 0
				  res1 = Math.ceil(num1 / num2);
				  console.log(res);
				  for (var z = 1; z < res1; z++) {
					  
					this.offset = this.offset + 5;
					//alert(this.offset)
					var request = {
						"app_name": localStorage.getItem('app_name'),
					// "app_name": "com.ttl.customersocialapp",
					"portal_app_name": "customersocialapp-portal",
					"app_version": "0.1",
					"device_id": "12345678",
					"offset": this.offset,
					"from_date":jcFromDate, 
					"to_date":jcToDate, 
					"size":5
					};
					this._ser.getNotification(request).subscribe(
			res => {
				this.spinnerService.show();
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
			console.log("Inner")
				console.log(jsonObj);
				
				for(var i:any=0;i<this.notificationData.notification_list.length;i++){
					let jsonObj2 = {};
					jsonObj2['Notification Id']=this.notificationData.notification_list[i].notification_id;
					jsonObj2['Created By']=this.notificationData.notification_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.notification_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.notification_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.notification_list[i].updated_dt;
					jsonObj2['Type']=this.notificationData.notification_list[i].notification_type;
					jsonObj2['Title']=this.notificationData.notification_list[i].notification_title;
					jsonObj2['Description']=this.notificationData.notification_list[i].notification_desc;
					jsonObj2['Notification Category']=this.notificationData.notification_list[i].notification_category;
					jsonObj2['Notification Base Category']=this.notificationData.notification_list[i].notification_base_category;
					jsonObj2['Start Date']=this.notificationData.notification_list[i].notification_startDate;
					jsonObj2['End Date']=this.notificationData.notification_list[i].notification_endDate;
					jsonObj2['State']=this.notificationData.notification_list[i].associated_states.toString();
					jsonObj2['City']=this.notificationData.notification_list[i].associated_cities.toString();
					jsonObj2['PPL']=this.notificationData.notification_list[i].associated_ppl.toString();
					jsonObj2['PL']=this.notificationData.notification_list[i].associated_pl.toString();
					jsonObj2['Kms Run']=this.notificationData.notification_list[i].kms_run;
					//jsonObj2['Kms Run Operator']=this.notificationData.notification_list[i].kms_run_operator;
					jsonObj2['IsChassis']=this.notificationData.notification_list[i].is_chassis_flag;
					if(this.notificationData.notification_list[i].is_chassis_flag){
						jsonObj2['IsChassis'] = 'Yes';
					}else{
						jsonObj2['IsChassis'] = 'No';
					}
					jsonObj2['Chassis'] =this.notificationData.notification_list[i].associated_chassis;
					jsonObj2['Is Published']=this.notificationData.notification_list[i].is_active;
					if(this.notificationData.notification_list[i].is_active){
						jsonObj2['Is Published'] = 'Yes';
					}else{
						jsonObj2['Is Published'] = 'No';
					}
				   this.globalData.push(jsonObj2);
					
				}
								},
			error => {
				//this.setResult(error);
				if (error.status == 401) {
					alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
			}
		);
		
					
				  }
				// console.log(this.globalData);
			// }
		// 	else{
		// 		let config = new MatSnackBarConfig();
    // config.verticalPosition = this.verticalPosition;
    // config.horizontalPosition = this.horizontalPosition;
		// config.duration = this.setAutoHide ? this.autoHide : 0;
		// this.message="No Data For download"
		// 		// alert("No Data For download")
		// 		this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
		// 		return
		// 	}
			},
			error => {
				let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
				//this.setResult(error);
				if (error.status == 401) {
					this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

					// alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

					// alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
			}
		);
		
		 
		//this.psfdata=JSON.parse(JSON.stringify(msg1));
		setTimeout(() => {
			this.spinnerService.show();
			if(this.globalData!=''){
            var ws = XLSX.utils.json_to_sheet(this.globalData);
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Notification Worksheet Data");
			XLSX.writeFile(wb, "Notification Worksheet.xlsx");
			}else{
				let config = new MatSnackBarConfig();
				config.verticalPosition = this.verticalPosition;
				config.horizontalPosition = this.horizontalPosition;
				config.duration = this.setAutoHide ? this.autoHide : 0;
				this.snackBar.open('No Data For Download', this.action ? this.actionButtonLabel : undefined, config);

			}
			this.spinnerService.hide();
		}, 1000);
		
	}
	downloadAsExcelFile_old()
	{
		
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		//  this.globalData=[]
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		
		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = fromDate;
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			  var jcToDate = toDate;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
		var request = {
			"app_name":localStorage.getItem('app_name'),
			// "app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": localStorage.getItem('device_id'),
			"offset": 0,
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"size":5
		};
			
		this._ser.getNotification(request).subscribe(
			res => {
				// this.globalData=[];
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				//console.log(jsonObj);
				console.log("AAAAAAAAAAA")
				console.log(this.notificationData)
				var num_list_cnt: number = this.notificationData.total_count;
				if(num_list_cnt!= 0){
							
				
				console.log("llll")
				for(var i:any=0;i<this.notificationData.notification_list.length;i++){
					let jsonObj2 = {};
					
					console.log(this.notificationData.notification_list[i]);
					jsonObj2['Notification Id']=this.notificationData.notification_list[i].notification_id;
					jsonObj2['Created By']=this.notificationData.notification_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.notification_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.notification_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.notification_list[i].updated_dt;
					jsonObj2['Type']=this.notificationData.notification_list[i].notification_type;
					jsonObj2['Title']=this.notificationData.notification_list[i].notification_title;
					jsonObj2['Description']=this.notificationData.notification_list[i].notification_desc;
					jsonObj2['Notification Category']=this.notificationData.notification_list[i].notification_category;
					jsonObj2['Notification Base Category']=this.notificationData.notification_list[i].notification_base_category;
					jsonObj2['Start Date']=this.notificationData.notification_list[i].notification_startDate;
					jsonObj2['End Date']=this.notificationData.notification_list[i].notification_endDate;
					jsonObj2['State']=this.notificationData.notification_list[i].associated_states.toString();
					jsonObj2['City']=this.notificationData.notification_list[i].associated_cities.toString();
					jsonObj2['PPL']=this.notificationData.notification_list[i].associated_ppl.toString();
					jsonObj2['PL']=this.notificationData.notification_list[i].associated_pl.toString();
					jsonObj2['Kms Run']=this.notificationData.notification_list[i].kms_run;
					//jsonObj2['Kms Run Operator']=this.notificationData.notification_list[i].kms_run_operator;
					jsonObj2['IsChassis']=this.notificationData.notification_list[i].is_chassis_flag;
					if(this.notificationData.notification_list[i].is_chassis_flag){
						jsonObj2['IsChassis'] = 'Yes';
					}else{
						jsonObj2['IsChassis'] = 'No';
					}
					jsonObj2['Chassis'] =this.notificationData.notification_list[i].associated_chassis;
					jsonObj2['Is Published']=this.notificationData.notification_list[i].is_active;
					if(this.notificationData.notification_list[i].is_active){
						jsonObj2['Is Published'] = 'Yes';
					}else{
						jsonObj2['Is Published'] = 'No';
					}
				   this.globalData.push(jsonObj2);
					
				  }
				var num1: number = this.notificationData.total_count;
			
				  var num2: number = this.notificationData.notification_list.length;
				  var res1: number = 0
				  res1 = Math.ceil(num1 / num2);
				 // console.log(res);
				  for (var z = 0; z < res1; z++) {
					  
					this.offset = this.offset + 5;
				//	alert(this.offset)
					var request = {
						"app_name": localStorage.getItem('app_name'),
					// "app_name": "com.ttl.customersocialapp",
					"portal_app_name": "customersocialapp-portal",
					"app_version": "0.1",
					"device_id": localStorage.getItem('device_id'),
					"offset": this.offset,
					"from_date":jcFromDate, 
					"to_date":jcToDate, 
					"size":5
					};
					this._ser.getNotification(request).subscribe(
			res => {
				
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(jsonObj);
				
				for(var i:any=0;i<this.notificationData.notification_list.length;i++){
					let jsonObj2 = {};
					console.log(this.notificationData.notification_list[i]);
					jsonObj2['Notification Id']=this.notificationData.notification_list[i].notification_id;
					jsonObj2['Created By']=this.notificationData.notification_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.notification_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.notification_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.notification_list[i].updated_dt;
					jsonObj2['Type']=this.notificationData.notification_list[i].notification_type;
					jsonObj2['Title']=this.notificationData.notification_list[i].notification_title;
					jsonObj2['Description']=this.notificationData.notification_list[i].notification_desc;
					jsonObj2['Notification Category']=this.notificationData.notification_list[i].notification_category;
					jsonObj2['Notification Base Category']=this.notificationData.notification_list[i].notification_base_category;
					jsonObj2['Start Date']=this.notificationData.notification_list[i].notification_startDate;
					jsonObj2['End Date']=this.notificationData.notification_list[i].notification_endDate;
					jsonObj2['State']=this.notificationData.notification_list[i].associated_states.toString();
					jsonObj2['City']=this.notificationData.notification_list[i].associated_cities.toString();
					jsonObj2['PPL']=this.notificationData.notification_list[i].associated_ppl.toString();
					jsonObj2['PL']=this.notificationData.notification_list[i].associated_pl.toString();
					jsonObj2['Kms Run']=this.notificationData.notification_list[i].kms_run;
					//jsonObj2['Kms Run Operator']=this.notificationData.notification_list[i].kms_run_operator;
					jsonObj2['IsChassis']=this.notificationData.notification_list[i].is_chassis_flag;
					if(this.notificationData.notification_list[i].is_chassis_flag){
						jsonObj2['IsChassis'] = 'Yes';
					}else{
						jsonObj2['IsChassis'] = 'No';
					}
					jsonObj2['Chassis'] =this.notificationData.notification_list[i].associated_chassis;
					jsonObj2['Is Published']=this.notificationData.notification_list[i].is_active;
					if(this.notificationData.notification_list[i].is_active){
						jsonObj2['Is Published'] = 'Yes';
					}else{
						jsonObj2['Is Published'] = 'No';
					}
				   this.globalData.push(jsonObj2);
					
				}
				// console.log()
				},
			error => {
				let config = new MatSnackBarConfig();
				config.verticalPosition = this.verticalPosition;
				config.horizontalPosition = this.horizontalPosition;
				config.duration = this.setAutoHide ? this.autoHide : 0;
				if (error.status == 401) {
					this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

					//alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

				//	alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
				//this.setResult(error);
				
			}
		);
		
					
					}
					
				console.log("Final Array")
				console.log(this.globalData);
			}else{
				let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
		this.message="No Data For download"
				// alert("No Data For download")
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

				return
			}
			},
			error => {
				let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
			//	this.setResult(error);
			if (error.status == 401) {
				this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

			//	alert(error.error.detail)
				// this._r.navigate(['/login']);
				this.setlogout()
			}
			if (error.status == 412) {
				this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

				// alert(error.error.msg)
				//this._r.navigate(['/login']);
			}
			}
		);
		// alert(this.globalData)
		 if(this.globalData!=''){
			 console.log("In Timeout")
			 console.log(this.globalData)
		//this.psfdata=JSON.parse(JSON.stringify(msg1));
		setTimeout(() => {
			
			this.spinnerService.show();
            var ws = XLSX.utils.json_to_sheet(this.globalData);
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Notification Data");
			XLSX.writeFile(wb, "Notification Worksheet.xlsx");
			this.spinnerService.hide();
		}, 1000);
	}
	}
	setlogout(){
		localStorage.setItem('access_token','');
		localStorage.setItem('device_id','');
		//location.reload();
		this._r.navigate(['/']);
		// return
	}
	search(){
		let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
		var flag=1;
		//alert('hii');
		if((this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "")&& (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == ""))
		{
			flag=0;
			this.spinnerService.hide();
			// alert("Select Date")
			this.message="Please Select From Date and To Date"
			// alert("No Data For download")
			this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
			return
		}else{
			if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
				var jcFromDate = '';
				flag=0;
			//	alert("Select From Date");
			this.message="Please Select From Date"
			this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
				var jcToDate = '';
				//alert("Select To Date");
				this.message="Please Select To Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

			//	$("#msgModal").modal('show');
				flag=0;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
			
			var isLarger = new Date(jcToDate) < new Date(jcFromDate);
			//alert(isLarger)
			if(new Date(jcToDate) < new Date(jcFromDate)){
				flag=0;
				//alert("To date must less than Start Date")
				this.message="To date must be greater than Start Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

			  //alert(flag)
			  // this.spinnerService.hide();
			}
			var timeDiff = Math.abs(new Date(jcFromDate).getTime() - new Date(jcToDate).getTime());
			var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
			
		}
		if(flag==1){
			this.generateTable(0,5,jcFromDate,jcToDate);
		}
		//alert(jcToDate +' ' + jcFromDate);
	
		/*this.spinnerService.show();
		var request = {
			"app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"offset":0,
			"size":5
		};
			
		this._ser.getNotification(request).subscribe(
			res => {
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['notification_list']);
				
				for(var i:any=0;i<res['notification_list'].length;i++){
					let jsonObj2 = {};
					//console.log(this.notificationData.notification_list[i]);
					res['notification_list'][i].notification_id=res['notification_list'][i].notification_id;
					res['notification_list'][i].created_by=res['notification_list'][i].notification_id;
					res['notification_list'][i].created_dt=res['notification_list'][i].created_dt;
					res['notification_list'][i].last_updated_by=res['notification_list'][i].notification_id;
					res['notification_list'][i].last_updated_at= res['notification_list'][i].updated_dt;
					res['notification_list'][i].notification_type=res['notification_list'][i].notification_type;
					res['notification_list'][i].notification_title=res['notification_list'][i].notification_title;
					res['notification_list'][i].notification_desc=res['notification_list'][i].notification_desc;
					res['notification_list'][i].notification_category=res['notification_list'][i].notification_category;
					res['notification_list'][i].notification_base_category=res['notification_list'][i].notification_base_category;
					res['notification_list'][i].notification_startDate=res['notification_list'][i].notification_startDate;
					res['notification_list'][i].notification_endDate=res['notification_list'][i].notification_endDate;
					res['notification_list'][i].associated_states=res['notification_list'][i].associated_states.toString();
					res['notification_list'][i].associated_cities=res['notification_list'][i].associated_cities.toString();
					res['notification_list'][i].associated_ppl=res['notification_list'][i].associated_ppl.toString();
					res['notification_list'][i].associated_pl=res['notification_list'][i].associated_pl.toString();
					res['notification_list'][i].kms_run=res['notification_list'][i].kms_run;
					//jsonObj2['Kms Run Operator']=res['notification_list'][i].kms_run_operator;
					if(res['notification_list'][i].is_chassis_flag){
					res['notification_list'][i].is_chassis_flag='Yes';
					}else{
					res['notification_list'][i].is_chassis_flag='No';
					}
					res['notification_list'][i].associated_chassis =res['notification_list'][i].associated_chassis.toString();;
					res['notification_list'][i].is_active=res['notification_list'][i].is_active;
					if(res['notification_list'][i].is_active){
					res['notification_list'][i].is_active='Yes';
					}else{
					res['notification_list'][i].is_active='No';
					}
				  // this.globalDataTable.push(jsonObj2);
					
				}
				//console.log(this.globalData);
				this.length = res['total_count'];
					this.dataSource = new MatTableDataSource(res['notification_list']);
					//console.log(' hii '+this.dataSource);
					this.dataSource.sort = this.sort;
			//this.globalDataTable=res['notification_list'];
			},
			error => {
				this.setResult(error);
			}
		);*/
		
		
	}
	setResult(res) {
		this.res = res;
		console.log(JSON.stringify(res));
	}
	
	public edit(id){
		// alert(id)
		localStorage.setItem("id",id);
		console.log( localStorage.getItem('id'))
		 this._r.navigate(['/edit']);
	}
	
}
