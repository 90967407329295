import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationComponent } from './notification/notification.component';
import { GenericFeedbackComponent } from './generic-feedback/generic-feedback.component';
import { PostServiceFeedbackComponent } from './post-service-feedback/post-service-feedback.component';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { AddNotificationChassisComponent } from './add-notification-chassis/add-notification-chassis.component';
if(localStorage.getItem('device_id')!=''){
  
}
// const AppRoutes: Routes = [
//   {
//     path: '',
//     redirectTo: 'login',
//     pathMatch: 'full',
// },
// {
//   path: 'dashboard',
//   component: DashboardComponent
// },
// // {path:"login",component:LoginComponent},

// // {path:"",component:LoginComponent},
// // {path:"dashboard",component:DashboardComponent},
// // {path:"notification",component:NotificationComponent},
// // {path:"genericfeedback",component:GenericFeedbackComponent},
// // {path:"postservicefeedback",component:PostServiceFeedbackComponent},
// // {path:"addnotification",component:AddNotificationComponent},
// // {path:"addnotificationchassis",component:AddNotificationChassisComponent},
// // {path:'notification/edit/:id',component:AddNotificationComponent},
// // {path:'notification/editchassis/:id',component:AddNotificationChassisComponent},
// ]

// @NgModule({
//   imports: [RouterModule.forRoot(AppRoutes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
export const AppRoutes: Routes = [
  // {
  //     path: '#',
  //     redirectTo: 'login',
  //     pathMatch: 'full',
  // },

  {path:"login",component:LoginComponent},
  // {path:"#/login",component:LoginComponent},
  // {path:"",component:LoginComponent},
  {path:"dashboard",component:DashboardComponent},
  {path:"notification",component:NotificationComponent},
  {path:"genericfeedback",component:GenericFeedbackComponent},
  {path:"postservicefeedback",component:PostServiceFeedbackComponent},
  {path:"addnotification",component:AddNotificationComponent},
  {path:"addnotificationchassis",component:AddNotificationChassisComponent},
  {path:'notification/edit/:id',component:AddNotificationComponent},
  {path:'notification/editchassis/:id',component:AddNotificationChassisComponent}
]
