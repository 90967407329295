import {  Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { MatPaginator, MatTableDataSource, MatInput,MatButtonModule } from '@angular/material';
import { FormBuilder , FormGroup, Validators, ValidationErrors,FormControl } from '@angular/forms';
//import { MatFileUploadModule, } from 'angular-material-fileupload';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import * as $ from "jquery";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { DatePipe } from '@angular/common'
import { validateConfig } from '@angular/router/src/config';


@Component({
  selector: 'app-add-notification-chassis',
  templateUrl: './add-notification-chassis.component.html',
  styleUrls: ['./add-notification-chassis.component.css']
})
export class AddNotificationChassisComponent implements OnInit {
  submitted = false;
  @ViewChild('form') form
  notifyChassisForm:FormGroup;
  res
  files: FileList;
  files1: FileList;
   imgs=[];
   globalData: any[] = [];
  //  globalData:any[]
   bbbb;
   c;
   message: string = 'Snack Bar opened.';
   //actionButtonLabel: string = 'Retry';
   actionButtonLabel: string = '';
   action: boolean = true;
   setAutoHide: boolean = true;
   autoHide: number = 2000;
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
   verticalPosition: MatSnackBarVerticalPosition = 'top';
   
   addExtraClass: boolean = false;
  //  jc_from_date: any = 'yyyy-mm-dd';
  //  jc_to_date: any = 'yyyy-mm-dd';
  jc_from_date: any = '';
   jc_to_date: any = '';
  // date = new FormControl(new Date());
  // notification_endDate = new FormControl((new Date()).toISOString());
  
  constructor(private _ser: BaseapiService, private _r: Router, el: ElementRef,private _fb:FormBuilder, private _ar: ActivatedRoute,public datepipe: DatePipe,public snackBar: MatSnackBar) {
    this.notifyChassisForm = this._fb.group({
      // FullName:['',[Validators.required,Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
      // Email:['',[Validators.required,Validators.pattern("[a-zA-Z0-9]{3,}[@]{1}[a-zA-Z0-9]{3,}[.]{1}[a-zA-Z0-9]{2,}")]],
      // MobileNo:['',[Validators.required,Validators.pattern("[0-9]{10}")]],
      // State:['',[Validators.required]],
     
      notification_title:['',[]],
      notification_type:['',[]],
      
      notification_category:['',[]],
      notification_desc:['',[]],
      notification_startDate:['',[]],
      notification_endDate:['',[]],
      kms_run_operator:['',[]],
      kms_run:['',[]],
      state:['',[]],
      // city:['',[]],
      // ppl:['',[]],
      pl:['',[]],
      banner_images:['',[]],
      // banner_images:[[],[]],
      addDynamicElement: this._fb.array([]),
      chassis_txtfile:['',[]],
      ready_to_publish:['false',[]],
    })
   }
   file
   ch_list;
   flag = false;
   ban_img
   chassislist
  
   
  ngOnInit() {
    var today = new Date();
    console.log(today)
    // let date = new Date();
    //     this.notifyChassisForm.patchValue({notification_endDate: {
    //     date: {
    //         year: date.getFullYear(),
    //         month: date.getMonth() + 1,
    //         day: date.getDate()}
    //     }});
    $('.notificationType').select2(
      {tags: true}
    )
    this._ser.getNotificationType().subscribe(res => {
      //alert("gggg")
      this.arrNotificationType =res;
        return this.arrNotificationType      
    },
    error => {
      if(error.status==401){
          let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
         this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
 
          this._r.navigate(['/login']);
          return
       // alert(error.error.detail)
        //this._r.navigate(['/login']);
      }
      })

      if (this._ar.snapshot.params.id != undefined) {
       
        this.flag=true;
       
       const id=this._ar.snapshot.params.id;

        var data = {
          "app_name": localStorage.getItem('app_name'),
          "portal_app_name": "customersocialapp-portal",
          "app_version": "0.1",
          "device_id": localStorage.getItem('device_id'),
          "id": id
        }
        
        
        //console.log(data)
        this._ser.userData(data).subscribe(res => {
          this.flag=true;
          
           var notification_endDate =this.datepipe.transform(res['notification']['notification_endDate'], 'MM/dd/yyyy');
        var start_date =this.datepipe.transform(res['notification']['notification_startDate'], 'MM/dd/yyyy');
        let date = new Date();
        this.notifyChassisForm.patchValue({notification_endDate: {
        date:notification_endDate
         

        // date: {
        //     year: date.getFullYear(),
        //     month: date.getMonth() + 1,
        //     day: date.getDate()}
        }
        
      });
      this.jc_from_date=start_date;
      this.jc_to_date=notification_endDate;
      this.notifyChassisForm.patchValue({notification_startDate: {date:start_date}});
      //this.notifyChassisForm.controls['notification_endDate'].patchValue({date:'11/07/2021'});

          this.notifyChassisForm.controls['notification_type'].patchValue(res['notification']['notification_type']);
          this.notifyChassisForm.controls['notification_category'].patchValue(res['notification']['notification_category']); 
          this.notifyChassisForm.controls['notification_title'].patchValue(res['notification']['notification_title']); 
          this.notifyChassisForm.controls['notification_desc'].patchValue(res['notification']['notification_desc']); 
          this.notifyChassisForm.controls['kms_run'].patchValue(res['notification']['kms_run']); 
          this.notifyChassisForm.controls['kms_run_operator'].patchValue(res['notification']['kms_run_operator']); 
          this.ban_img=res['notification']['banner_images']
          this.chassislist=  res['notification']['associated_chassis']
          // alert(this.chassislist)    
         if(res['notification']['is_active']==true){
          this.notifyChassisForm.controls['ready_to_publish'].patchValue('true');
         }else{
          this.notifyChassisForm.controls['ready_to_publish'].patchValue('false');
         }
/********************************************* */
         },
          error => {
            if (error.status == 401) {
    
              alert(error.error.detail)
              this._r.navigate(['/login']);
            }
            if(error.status == 412) {
              alert(error.error.msg)
            }
          })
      } else {
        this.flag = false;
      }


  }
  arrNotificationType;
	public myDate: IMyDate = {

		year: this.getCurrentDay('Y'),
		month: this.getCurrentDay('M'),
		day: this.getCurrentDay('D'),
	}
  
  
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    
    
    var day = today.getDate();
    if (day != 1) {
      day = day - 1;
    }
    
    var month = today.getMonth() + 1; //January is 0!
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    markCurrentDay: true,
  }; 
  
  feedback_from_date:any='yyyy-mm-dd';
  feedback_to_date:any='yyyy-mm-dd';
 
  
  onJcFromDateChanged(event: IMyDateModel) {
    if(event.formatted!=''){
    this.jc_from_date = event.formatted;
    }else{
      this.jc_from_date=''; 
    }
  }
  onJcToDateChanged(event: IMyDateModel) {
    if(event.formatted!=''){
      this.jc_to_date = event.formatted;
    }else{
      this.jc_to_date = '';
    }
  }
	// onJcFromDateChanged(event: IMyDateModel) {
	// 	this.jc_from_date = event.formatted;
	// 	console.log(this.jc_from_date);
	//   }
	  // onJcToDateChanged(event: IMyDateModel) {
      
    //   if(event.formatted){
    //     this.jc_to_date = event.formatted;
    //   }
		
		// console.log(this.jc_to_date);
	  // } 
 
  view_notification(data)
  {
	 console.log(data) 
  }
  fileChanged(e) {
    
    this.file = e.target.files[0];
}
downloadchassis(){
    for(var i:any=0;i<this.chassislist.length;i++){
    let jsonObj2 = {};
    if(this.chassislist[i]!='' && this.chassislist[i]!= undefined ){
      console.log(this.chassislist[i])
      jsonObj2['Chassis Num']=this.chassislist[i];
    }
    this.globalData.push(jsonObj2)
    //console.log(this.globalData)
   // 
  }
  
  // console.log(this.globalData)
  
  var chassis=[{notification_id: 59, app_name: "com.ttl.customersocialapp"}] 
  var ws = XLSX.utils.json_to_sheet(this.globalData);
  var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "PSF Dealer Data");
  XLSX.writeFile(wb, "Chassis List.xlsx");
}
getFiles(event) {
  var image =document.getElementById('testfile');

  //alert(event.target.offsetWidth) 
  //console.log("Jjjjjjj")
//console.log(JSON.stringify(image)  );
  //var width = image.naturalWidth;
//var height = image.naturalHeight;
  //console.log("dddddd"+(this.logo.nativeElement as HTMLImageElement).width);
  //this.files = event.target.files;
  
  this.files1= event.target.files
  var width=event.currentTarget.offsetWidth;
  var imgheight= event.currentTarget.offsetHeight;
 // alert(width);
 
  
  //console.log(this.files1);
 // console.log(this.files);
 // console.log("Fiiiiiillllles");
  if (this.files1 != undefined && this.files1 != null) {
    var fileSize = this.files1[0].size;
    var img_name=this.files1[0].name;
    if (/\s/.test(img_name)) {
      alert('Plaese upload image having no space in Name');
      //this.refreshPage();
      // It has any kind of whitespace
    }
 

    if (fileSize >= 5242880) {
      alert('Please select a file below 5MB');
      //this.refreshPage();
    }
  }
  

}

addNotification(data){
  var a;
  if(this.file!=undefined){
    a=this.addNotification_upload(data)
  }else{
     a=this.addNotification_withoutChassis(data)
    console.log("File Not Exists");
  }

//alert(this.c)
// var i = a.then((val) => {return val});
console.log(a)
}
addNotification_withoutChassis(data){
  let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
  var notificationType= $('.notificationType').val();
  let formData: FormData = new FormData();
  let fileReader = new FileReader();
  if(data['notification_category']==''){
    this.message="Enter Notification Category"
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    return
  }else if(notificationType=='' ||notificationType==undefined ){
    this.message="Select Notification Type"
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    return
    }else if(data['notification_title']=='' ||data['notification_title']==undefined ){
      this.message="Enter Notification Title"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
      return
    }
      else if(data['notification_desc']==''){
        this.message="Enter Notification Description"
        this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        return
        }
        else if(data['notification_endDate']==undefined|| data['notification_endDate']==''){
        this.message="Select Notification To Date"
        this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        return
        }else if(data['notification_startDate']==undefined || data['notification_startDate']==''){
        this.message="Select Notification From Date"
        this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        return
        }
        var today = new Date();
        var latest_date =this.datepipe.transform(today,'MM/dd/yyyy');
        if(new Date(this.jc_to_date) < new Date(this.jc_from_date)){
          // flag=0;
           //alert("To date must less than Start Date")
           this.message="To date must be greater than From Date"
           this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            
           return
          }
            else if((new Date(latest_date))> (new Date(this.jc_from_date))){
              alert("From Date must be greater than Current Date" )
              return;
              }else if((new Date(latest_date))> (new Date(this.jc_to_date))){
                alert("To Date must be greater than Current Date" ) 
                return
                }
            // alert(this.chassislist)
            // return
            formData.append('chassis_list',this.chassislist);
            formData.append('app_name', localStorage.getItem('app_name'));
            formData.append('portal_app_name', "customersocialapp-portal");
            // formData.append('notification_id',this._ar.snapshot.params.id);
            formData.append('all_cities_flag', 'false');
            formData.append('app_version', "0.1")
            formData.append('city',"all")
            formData.append('device_id',localStorage.getItem('device_id'))
            formData.append('is_active',data['ready_to_publish'])
            formData.append('is_chassis_flag','true')
            formData.append('jdp_flag',"Y")
            formData.append('kms_run', data['kms_run'])
            formData.append('kms_run_operator',data['kms_run_operator'])
            formData.append('notification_base_category',"Information")
            formData.append('notification_category', data['notification_category'])
            formData.append('notification_desc', data['notification_desc'])
            //data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
            //formData.append('notification_endDate',data['notification_endDate'].formatted+' 23:59:59');
            ///data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';
           
            if(data['notification_endDate'].formatted==undefined){
              data['notification_endDate']= this.jc_to_date+' 23:59:59'
               
              }else{
                data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
              }
              if(data['notification_startDate'].formatted==undefined){
              data['notification_startDate']= this.jc_from_date+' 00:00:01'
              }else{
                data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';
              }
        
            formData.append('notification_endDate', data['notification_endDate'])
            formData.append('notification_startDate',data['notification_startDate'])
            formData.append('notification_title',data['notification_title'])
            formData.append('notification_type',notificationType)
            formData.append('pl', "all")
            formData.append('ppl', "all")
            formData.append('ready_to_publish',data['ready_to_publish'])
            formData.append('state', "all")
            if (this._ar.snapshot.params.id == undefined){
              if(this.files1!=undefined){
                formData.append('banner_images',this.files1[0])
              }
              // else{
              //   formData.append('banner_images','')
              
              // }
                this._ser.addNotificationServ(formData).subscribe(res => {
                  // alert(res['msg'])
                  this.snackBar.open(res['msg'], this.action ? this.actionButtonLabel : undefined, config);
                  this._r.navigate(['/notification']);
                  return
               },
                error => {
                  if (error.status == 401) {
          
                   // alert(error.error.detail)
                   this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
           
                    this._r.navigate(['/login']);
                    return
                  }
                  if (error.status == 412) {
                    this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
           
                    this._r.navigate(['/login']);
                    return
                    //alert(error.error.msg)
                    //this._r.navigate(['/login']);
                  }
                })
              } else {
                if(this.files1!=undefined){
                  formData.append('banner_images',this.files1[0])
                }
                var imag=[]
                // alert('LLL')
                formData.append('notification_id',this._ar.snapshot.params.id);  
                 data['notification_id']=this._ar.snapshot.params.id;
                // imag.push(data['banner_images']);
                 //data['banner_images']=imag
                 //console.log("Inner Edit Data");
                 //console.log(data);
           //return
                 this._ser.update(formData).subscribe(
                   res => {
                     //alert()
                     this.res=JSON.parse(JSON.stringify(res));
                    //  alert(this.res.msg)
                    this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);

                     this._r.navigate(['/notification']);
                    
                     //console.log("Inner", res);
                     this.setResult(res);
                     return
                   },
                   error => {
                     if (error.status == 401) {
                      //  alert(error.error.detail)
                      this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                       // this._r.navigate(['/login']);
                       this.setlogout()
                     }
                     if (error.status == 412) {
                      //  alert(error.error.msg)
                      this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                       //this._r.navigate(['/login']);
                     }
                     //this.setResult(error);
                   }
                 );
               }

}
addNotification_upload(data){
//   this.submitted = true;
//   if (this.notifyChassisForm.invalid) {
//     return;
// }

  var notificationType= $('.notificationType').val();
  console.log(this.file)
  // return
  let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;


  let formData: FormData = new FormData();
  let fileReader = new FileReader();
  var data1;
  var promise
  if(this.file!=undefined){
 var u=fileReader.readAsText(this.file);
    
  promise = new Promise((resolve, reject) => {
    //TODO
    fileReader.onload = (e) => {
      // (fileReader.result).replace(/,/g, ", ")
      resolve(fileReader.result)
      console.log(fileReader.result)
    }
    // resolve('done');
  });
  console.log(promise) ;
}
/******************************* */

   if(data['notification_category']==''){
    this.message="Enter Notification Category"
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    return
  }else if(data['notification_title']=='' ||data['notification_title']==undefined ){
    this.message="Enter Notification Title"
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    return
  }else if(notificationType=='' ||notificationType==undefined ){
    this.message="Enter Notification Type"
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    return
    }
    else if(data['notification_desc']==''){
      this.message="Enter Notification Description"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
      return
      }
    else if(data['notification_startDate']==undefined){
      this.message="Enter Notification Start Date"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
      return
      }
      else if(data['notification_endDate']==undefined|| data['notification_endDate']==''){
      this.message="Enter Notification End Date"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
      return
      }
      
      if(this.file==undefined){
        this.snackBar.open('Select Chassis List', this.action ? this.actionButtonLabel : undefined, config);
        return
        //alert("Select Chassis List")
       // return
      }
/******************************* */
//return

  var b=promise.then((val) => {
    //return val
    data1=val;
   // data1.push(val);
    //console.log(data1);
    // console.log(this.files1)
    //var arrayImg=[this.files1[0],this.files1[1]]
    //console.log(arrayImg)
    // alert(new Date(data['notification_startDate'].formatted))
    if(new Date(data['notification_endDate'].formatted) < new Date(data['notification_startDate'].formatted)){
     // flag=0;
      //alert("To date must less than Start Date")
      this.message="To date must be greater than Start Date"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
       }
    // if(notificationType=='' ||notificationType==undefined ){
    //   this.message="Enter Notification Type"
    //   this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //   return
    //   }else if(data['notification_category']==''){
    //     this.message="Enter Notification Category"
    //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //     return
    //   }else if(data['notification_title']=='' ||data['notification_title']==undefined ){
    //     this.message="Enter Notification Title"
    //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //     return
    //   }
    //     else if(data['notification_desc']==''){
    //       this.message="Enter Notification Description"
    //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //       return
    //       }
    //       else if(data['notification_endDate']==undefined|| data['notification_endDate']==''){
    //       this.message="Enter Notification End Date"
    //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //       return
    //       }else if(data['notification_startDate']==undefined){
    //       this.message="Enter Notification Start Date"
    //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
    //       return
    //       }
    //       else
           if(this.file==undefined){
          this.snackBar.open('Upload Chassis List', this.action ? this.actionButtonLabel : undefined, config);
            //alert("Select Chassis List")
          return
          }
          
          
/********************************************* */

    formData.append('chassis_list',data1);
    // formData.append('chassis_list','MAT1121212122121212,MAT1121212122121216,MAT1121212122121219')
    formData.append('app_name', localStorage.getItem('app_name'));
    formData.append('portal_app_name', "customersocialapp-portal");
    // formData.append('notification_id',this._ar.snapshot.params.id);
    formData.append('all_cities_flag', 'false');
    formData.append('app_version', "0.1")
    formData.append('city',"all")
    formData.append('device_id',localStorage.getItem('device_id'))
    formData.append('is_active',data['ready_to_publish'])
    formData.append('is_chassis_flag','true')
    formData.append('jdp_flag',"Y")
    formData.append('kms_run', data['kms_run'])
    formData.append('kms_run_operator',data['kms_run_operator'])
    formData.append('notification_base_category',"Information")
    formData.append('notification_category', data['notification_category'])
    formData.append('notification_desc', data['notification_desc'])
    //data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
    //formData.append('notification_endDate',data['notification_endDate'].formatted+' 23:59:59');
    ///data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';
   
    if(data['notification_endDate'].formatted==undefined){
      data['notification_endDate']= this.jc_to_date+' 23:59:59'
       
      }else{
        data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
      }
      if(data['notification_startDate'].formatted==undefined){
      data['notification_startDate']= this.jc_from_date+' 00:00:01'
      }else{
        data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';
      }

    formData.append('notification_endDate', data['notification_endDate'])
    formData.append('notification_startDate',data['notification_startDate'])
    formData.append('notification_title',data['notification_title'])
    formData.append('notification_type',notificationType)
    formData.append('pl', "all")
    formData.append('ppl', "all")
    formData.append('ready_to_publish',data['ready_to_publish'])
    formData.append('state', "all")

   if (this._ar.snapshot.params.id == undefined){
    if(this.files1!=undefined){
      formData.append('banner_images',this.files1[0])
    }else{
      formData.append('banner_images','')
    
    }
    console.log(JSON.stringify(formData))
      this._ser.addNotificationServ(formData).subscribe(res => {
        // alert(res['msg'])
        this.snackBar.open(this.res['msg'], this.action ? this.actionButtonLabel : undefined, config);

        this._r.navigate(['/notification']);
                    return
     },
      error => {
        if (error.status == 401) {

         // alert(error.error.detail)
          this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
          this._r.navigate(['/login']);
          return
        }
        if (error.status == 412) {
          this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
 
          this._r.navigate(['/login']);
          return
          //alert(error.error.msg)
          //this._r.navigate(['/login']);
        }
      })
    } else {
      if(this.files1!=undefined){
        formData.append('banner_images',this.files1[0])
      }
      var imag=[]
      // alert('LLL')
      formData.append('notification_id',this._ar.snapshot.params.id);  
       data['notification_id']=this._ar.snapshot.params.id;
      // imag.push(data['banner_images']);
       //data['banner_images']=imag
       //console.log("Inner Edit Data");
       //console.log(data);
 //return
       this._ser.update(formData).subscribe(
         res => {
           //alert()
           this.res=JSON.parse(JSON.stringify(res));
          //  alert(this.res.msg)
          this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);

           //console.log("Inner", res);
           this.setResult(res);
         },
         error => {
           if (error.status == 401) {
            this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

            //  alert(error.error.detail)
             // this._r.navigate(['/login']);
             this.setlogout()
           }
           if (error.status == 412) {
            this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

            //  alert(error.error.msg)
             //this._r.navigate(['/login']);
           }
           //this.setResult(error);
         }
       );
     }
console.log(data)
// console.log(formData)





/*********************************************** */
  });
 
   return b
 // console.log(b)
}
  addNotification1(data){

   console.log(this.files1)
   
   console.log("hiiii ")
   //console.log(data)
    //console.log(data['chassis_list'])
    var c;
    var imgs =[];
    let formData: FormData = new FormData();
    //formData.append('banner_images',"data");
    //formData.append('endDate',data['notification_endDate'].formatted+' 23:59:59')
    console.log("Formdata is")
    console.log(formData)
  //   if (this.files1 != undefined && this.files1 != null) {
  //     var i;
  //     for(i=0;i<this.files1.length;i++){
  //       imgs.push(this.files1[i])
  //     }
     
  //  // formData.append('banner_images',"test");
  //   }
    
    // formData.append('files', this.files1[0]);
    // formData.append('banner_images', this.files1[0]);
    // formData.append('app_name', "com.tatamotors.ifiradmin");
    // formData.append('bu_name', "tmpc");

    //console.log("Array"+imgs)
    //console.log(this.files1)
    //console.log(imgs)
    //console.log(data)
   // data['banner_images']=this.files1[0]
    //console.log(this.files1[0])

    //console.log(data)

    // const obj = {5.0: 10, 28.0: 14, 3.0: 6};
   
    //data['banner_images']="["+this.files1[0]+"]"
  //  data['banner_images']="["+this.files1[0]+"]"
    //console.log(this.files1[0])
    if (this.files1 != undefined && this.files1 != null) {
      console.log("In the Loop")
      //console.log(this.files1)
      imgs.push(this.files1[0])
    // data['banner_images[]']= imgs
    // data['banner_images']=this.files1[0];
      //console.log("TtTT")
    }

    data['banner_images']=imgs;
//console.log(data)
    // else{
    //   data['banner_images']='';
    // }
    
    console.log("ttt333"+this.file)
    if(this.file!= undefined){
      console.log("In the loop")
     //var t= await readTextFile(this.file)
    // alert(t)
    //  console.log(t)
    let fileReader = new FileReader();
    var u=fileReader.readAsText(this.file);
      

      if(fileReader.result!=undefined || fileReader.result!=null){
      fileReader.onload = (e) => {
        console.log("In onload")
        this.c= fileReader.result;
        
        console.log(this.c)
        this.bbbb=fileReader.result;
        //console.log(this.bbbb)
        data['chassis_list']=fileReader.result
        formData.append('chassis_list123',this.bbbb)
      }
      console.log("After Log")
      console.log(this.c) 
        
    }
    
  
   
   return
   console.log("GGGG")
   console.log(this.bbbb)
   // console.log("Chassss123")
    //console.log(this.bbbb)
    
    //console.log("Chass")
    //console.log(data['chassis_list'])
    //console.log(this.file)
    }
    // else{
    //   data['chassis_list']='';
    // }
//console.log(data['banner_images']);
   
   // console.log(c)
//     var state = $('.statenames').val();
//     var city= $('.citylist').val();
//     var ppl= $('.ppllist').val();
//     var pl= $('.pllist').val();
// for(var i=0;i<pl.length;i++){
//   console.log(pl[i].ppl)
// }
    // alert(pl.toString());
    // var ppl = $('.ppllist').val();
    if(data['notification_endDate'].formatted){
      var today = new Date();

    }
    var today = new Date();
    var latest_date =this.datepipe.transform(today,'MM/dd/yyyy');
      // if((new Date(latest_date))> (new Date(data['notification_startDate'].formatted))){
      // alert("Start Date must be greater than Current Date")
      // return;
      // }
      if((new Date(latest_date))> (new Date(data['notification_endDate'].formatted))){
        alert("End Date must be greater than Current Date" )
        return; 
        }
   
        if(data['notification_endDate'].formatted==undefined){
          data['notification_endDate']= this.jc_to_date+' 23:59:59'
           
          }else{
            data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
          }
          if(data['notification_startDate'].formatted==undefined|| data['notification_startDate']==''){
          data['notification_startDate']= this.jc_from_date+' 00:00:01'
          }else{
            data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';
          }
    data['app_name']=localStorage.getItem('app_name');

   // data['app_name']='com.ttl.customersocialapp';
    //data['notification_type']='Information';
   // data['notification_category']='Launch';
    data['notification_base_category']='Information';
    data['portal_app_name']='customersocialapp-portal';
    data['app_version']= '0.1';
    // data['device_id']='33178477';
    data['device_id']=localStorage.getItem('device_id');
    //data['state']='all'
    data['city']='all'
    //data['ppl']='all';
    //data['pl']='all';
    data['is_chassis_flag']=true
    //data['is_chassis_flag']=true// foe chassis present
    data['all_cities_flag']=false
    data['is_active']=true
    //data['chassis_list']='MAT1121212122121212,MAT1121212122121216,MAT1121212122121219'
    data['jdp_flag']='Y';


    /***********************************************/
    if(this.files1!=undefined){
      formData.append('banner_images',this.files1[0])
    }else{
      formData.append('banner_images','')

    }
   
    formData.append('app_name', localStorage.getItem('app_name'));
    formData.append('portal_app_name', "customersocialapp-portal");
    formData.append('notification_id',this._ar.snapshot.params.id);
    formData.append('all_cities_flag', 'false');
   // formData.append('app_name',"com.ttl.customersocialapp");
    formData.append('app_version', "0.1")
    //banner_images: "C:\fakepath\45-452669_open-walking-man-clipart.png"
    //banner_images[]: [{}]
    formData.append('chassis_txtfile', "")
    // formData.append('chassis_list',data['chassis_list'])
    formData.append('city',"all")
    formData.append('device_id',localStorage.getItem('device_id'))
    formData.append('is_active',data['is_active'])
    formData.append('is_chassis_flag','true')
    formData.append('jdp_flag',"Y")
    formData.append('kms_run', data['kms_run'])
    formData.append('kms_run_operator',data['kms_run_operator'])
    formData.append('notification_base_category',"Information")
    formData.append('notification_category', data['notification_category'])
    formData.append('notification_desc', data['notification_desc'])
    formData.append('notification_endDate',data['notification_endDate'])
    
    formData.append('notification_startDate',data['notification_startDate'])
    formData.append('notification_title',data['notification_title'])
    formData.append('notification_type',data['notification_type'])
    formData.append('pl', "all")
   
    formData.append('ppl', "all")
    formData.append('ready_to_publish',data['ready_to_publish'])
    formData.append('state', "all")
    /*********************************************** */
    //console.log("After");
   //console.log(data);
   console.log("jjjj"+JSON.stringify(formData));
   //return

   if (this._ar.snapshot.params.id == undefined){
    //this._ser.addNotificationServ(data).subscribe(res => {
      this._ser.addNotificationServ(formData).subscribe(res => {
     // return res;
      //console.log(res)
      $("#msgModal").modal('show');
    },
      error => {
        if (error.status == 401) {

          //alert(error.error.detail)
          //this._r.navigate(['/login']);
          let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
         this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
         this.setlogout()
        }
        if (error.status == 412) {
          let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
         this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
 
          // alert(error.error.msg)
          //this._r.navigate(['/login']);
        }
      })
    }else {
      let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
      var imag=[]
      // alert('LLL')  
       data['notification_id']=this._ar.snapshot.params.id;
      // imag.push(data['banner_images']);
       //data['banner_images']=imag
       //console.log("Inner Edit Data");
       //console.log(data);
 //return
       this._ser.update(formData).subscribe(
         res => {
           //alert()
           this.res=JSON.parse(JSON.stringify(res));
          //  alert(this.res.msg)
          this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);
          this._r.navigate(['/notification']);
           //console.log("Inner", res);
           this.setResult(res);
         },
         error => {
           if (error.status == 401) {
            this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

            //  alert(error.error.detail)
             this._r.navigate(['/login']);
             this.setlogout()
           }
           if (error.status == 412) {
            this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

            //  alert(error.error.msg)
             this._r.navigate(['/login']);
           }
           //this.setResult(error);
         }
       );
     }

    
  }
//   readTextFile(file){
//     var data1
//     let fileReader = new FileReader();
//      //var c='';
//      console.log(fileReader.result)
//     if(fileReader.result!= undefined || fileReader.result!=null){
//     fileReader.onload = (e) => {
//       console.log("In File")
//       console.log(e)
//       this.c = fileReader.result;
//       //return fileReader.result;
//       //data1['chassis_list']= fileReader.result
//       console.log("--"+this.c)
//       this.bbbb=fileReader.result;
//       //console.log(this.bbbb)
//       //data['chassis_list']=fileReader.result
//       //formData.append('chassis_list123',this.bbbb)
//     }
      
      
//    }
//    console.log("--"+this.c)
//    //console.log(this.c)
//  var u=fileReader.readAsText(file);
// console.log("In other Function")
// // console.log(u)
//  //return
//   }

  setResult(res) {
    this.res = res;
    console.log(res)
   // $("#msgModal").modal('show');
    // $("#msgModal").fadeIn(3000, function() {
    //   this.closeModel();
    // });
    // $('#msgModal').model('show');
    // console.log(res);
  }
  setlogout(){
    localStorage.setItem('access_token','');
    localStorage.setItem('device_id','');
    this._r.navigate(['/login']);
  }
  submitform(files: FileList){
    
     // alert()
     let formData: FormData = new FormData();
     if (this.files1 != undefined && this.files1 != null) {
    // formData.append('banner_images', this.files1[0]);
    var i;
    //console.log(this.files1.length)
    for(i=0;i<this.files1.length;i++){
      console.log(this.files1[i])
      this.imgs.push(JSON.stringify(this.files1[i]));
      
    }
    // console.log
    var t :any=this.imgs;
    formData.append('banner_images',t)
     formData.append('app_name', "com.ttl.customersocialapp");
     formData.append('portal_app_name', "customersocialapp-portal");
     formData.append('notification_id',this._ar.snapshot.params.id);
     formData.append('all_cities_flag', 'false');
     formData.append('app_name',"com.ttl.customersocialapp");
     formData.append('app_version', "0.1")
     //banner_images: "C:\fakepath\45-452669_open-walking-man-clipart.png"
     //banner_images[]: [{}]
     formData.append('chassis_txtfile', "")
     formData.append('city',"all")
     formData.append('device_id',"42550570")
     formData.append('is_active','true')
     formData.append('is_chassis_flag','true')
     formData.append('jdp_flag',"Y")
     formData.append('kms_run', "8000")
     formData.append('kms_run_operator',"1212")
     formData.append('notification_base_category',"Information")
     formData.append('notification_category', "Launch")
     formData.append('notification_desc', "An update from backend")
     formData.append('notification_endDate',"11/25/2021 23:59:59")
     formData.append('notification_id', "54")
     formData.append('notification_startDate',"05/31/2019 00:00:01")
     formData.append('notification_title',"Notification title")
     formData.append('notification_type',"Information")
     formData.append('pl', "all")
     formData.append('portal_app_name',"customersocialapp-portal")
     formData.append('ppl', "all")
     formData.append('ready_to_publish',"yes")
     formData.append('state', "all")




     }
     console.log(formData)
     this._ser.update(formData).subscribe(res => {
      // return res;
       //console.log(res)
       //$("#msgModal").modal('show');
     },
       error => {
         if (error.status == 401) {
 
           alert(error.error.detail)
           this._r.navigate(['/login']);
         }
       })
    }
}
